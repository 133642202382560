.hover-actions:hover {
  color: #2962ff;
  transition: color 0.2s ease-in-out;
}

.hover-actions {
  position: relative; /* Required for positioning the pseudo-element */
  text-decoration: none; /* Remove default underline */
  color: inherit; /* Inherit the text color */
}

/* Style for the vertical line */
.hover-actions::after {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  top: 0; /* Start from the top */
  left: -2px; /* Position on the left side of the link */
  width: 2px; /* Width of the line */
  height: 100%; /* Height of the line */
  background-color: #7f7f7f; /* Color of the line */
  transition: height 0.5s ease-in-out; /* Transition for the height */
  transform: scaleY(0); /* Initially, set the height to 0 */
  transform-origin: top left; /* Set the transformation origin to the top left corner */
}

/* When hovering over the link, expand the line from top to bottom */
.hover-actions:hover::after {
  transform: scaleY(1); /* Expand the line to full height */
}

.submenu {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0.5px);
  -webkit-backdrop-filter: blur(0.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
