@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: "Lato", sans-serif;
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.7)),
    url(https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80)
      fixed center center;
}
.ctb {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.4)),
    url(./images/Team.jpg) fixed center center;
}
.ctc {
  background: linear-gradient(rgba(2, 2, 2, 0.7), rgba(0, 0, 0, 0.4)),
    url(./images/eligibility.jpg) fixed center center;
}

.main-img {
  height: 350px;
  margin: 10px;
  width: 100%;
  /* object-fit: fill; */
}
